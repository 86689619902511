<template>
  <div id="container">
    <div v-if="testType === 'reading'">
      <TableLoading v-if="loading"></TableLoading>
      <div
        class="responsive-reading-container"
        v-else
        v-for="(topic, topicIndex) in listTopics"
        :key="topicIndex"
        v-bind:style="[topicIndex === currentIndex ? {} : { display: 'none' }]"
        style="padding: 10px; padding-bottom: 0px; height: 100vh"
      >
        <a-card
          v-bind:style="[
            topicIndex === currentIndex ? {} : { display: 'none' },
          ]"
          style="margin-bottom: 0.5rem; height: 3rem; padding: 5px"
        >
          <div style="margin-top: -0.6rem">
            <h5 v-html="topic.topicName" style="max-width: 93%"></h5>

            <div
              style="
                position: absolute;
                right: 10%;
                z-index: 100;
                top: 20%;
                display: flex;
                align-items: center;
              "
            >
              <b style="font-size: 13px">A</b>
              <input
                style="margin-left: 5px; margin-right: 5px"
                @change="(val) => handleChangeFontSize(val)"
                class="range-slider__range"
                type="range"
                value="14"
                min="8"
                max="25"
              />

              <b style="font-size: 20px">A</b>
            </div>

            <a-divider
              type="vertical"
              style="position: absolute; top: 10%; right: 8.5%; height: 39px"
            />
            <a-statistic-countdown
              v-if="countDown"
              :value="countDown"
              @finish="onFinish"
              :valueStyle="{ color: isShowRedCountDown ? 'red' : 'inherit' }"
              :class="{ 'animate-red': isShowRedCountDown }"
              :format="isShowRedCountDown ? 's' : 'HH:mm:ss'"
              style="position: absolute; right: 2%; z-index: 100; top: 13%"
            />
          </div>
        </a-card>
        <a-card
          class="responsive-card-container"
          v-bind:style="[
            topicIndex === currentIndex ? {} : { display: 'none' },
          ]"
          style="height: 87%; margin-bottom: 0.3rem"
        >
          <div style="display: flex" class="do_test responsive-reading">
            <div
              style="
                max-width: 50%;
                width: 50%;
                height: 83vh;
                max-height: 100%;
                padding: 5px 15px 5px 5px;
                overflow-y: scroll;
              "
              class="edu-editor responsive-reading-topic"
              v-html="topic.content"
            ></div>

            <a-divider
              class="responsive-divider"
              style="margin-left: -0.5rem; background: black; display: none"
            />
            <a-divider
              type="vertical"
              style="
                margin-top: -0.25rem;
                margin-left: -0.25rem;
                height: calc(100dvh - 150px);
                background: #d3d3d3;
              "
            />
            <div
              style="
                padding: 10px;
                max-width: 50%;
                width: 50%;
                height: 83vh;
                max-height: 100%;
                overflow-y: scroll;
              "
              class="responsive-reading-question"
            >
              <div class="edu-editor" v-html="topic.description"></div>
              <div
                v-for="(
                  questionGroup, questionGroupIndex
                ) in topic.listQuestionGroups"
                :key="questionGroupIndex"
              >
                <div>
                  <div
                    v-html="questionGroup.content"
                    style="margin-bottom: -0.5rem"
                  ></div>
                  <a-collapse
                    :bordered="false"
                    v-for="(
                      question, questionIndex
                    ) in questionGroup.listQuestions"
                    :key="questionIndex + 1"
                    :value="question"
                    @change="
                      handleCollapse(questionGroup, question, questionIndex)
                    "
                  >
                    <template #expandIcon="props">
                      <a-icon
                        style="padding-bottom: 2px"
                        type="caret-right"
                        :rotate="props.isActive ? 90 : 0"
                      />
                    </template>
                    <a-collapse-panel
                      :disabled="
                        question.questionType === 'Matching Information' ||
                        question.questionType === 'Map Labelling (dropdown)'
                      "
                      :class="
                        handleCreateClassName(
                          topicIndex,
                          questionGroupIndex,
                          question,
                          questionIndex
                        )
                      "
                      style="background: white"
                      :key="questionIndex + 1"
                      :forceRender="true"
                    >
                      <template slot="header">
                        <div
                          v-if="
                            question.questionType !== 'Matching Information' &&
                            question.questionType !== 'Map Labelling (dropdown)'
                          "
                          style="display: flex; padding-bottom: 0px"
                        >
                          <div style="display: flex; align-items: center">
                            <b
                              style="margin-right: 10px; max-width: 5rem"
                              v-bind:style="[
                                question.questionNumber.length > 2
                                  ? { width: '40px' }
                                  : {},
                              ]"
                              >{{ question.questionNumber }}</b
                            >
                          </div>

                          <span>{{ question.questionTitle }}</span>
                        </div>
                        <div v-else style="display: flex">
                          <b
                            style="
                              margin-right: 10px;
                              display: flex;
                              align-items: center;
                            "
                            >{{ question.questionNumber }}</b
                          >
                          <span
                            style="
                              width: 90%;
                              display: flex;
                              align-items: center;
                            "
                            >{{ question.questionTitle }}</span
                          >
                          <div>
                            <a-select
                              v-if="!question.isRomanNumber"
                              v-model="question.valueForRadio"
                              size="default"
                              style="width: 80px; height: 2rem"
                              @change="
                                (val) => {
                                  question.listKeys = [
                                    formatStartOfQuestion(val - 1),
                                  ];
                                }
                              "
                            >
                              <a-select-option
                                v-for="(item, index) in 27"
                                :key="item"
                                :value="item"
                              >
                                {{ formatStartOfQuestion(index) }}
                              </a-select-option>
                            </a-select>
                            <a-select
                              v-else
                              size="default"
                              style="width: 80px; height: 2rem"
                              v-model="question.valueForRadio"
                              @change="
                                (val) => {
                                  question.listKeys = [
                                    formatStartOfQuestionRoman(val - 1),
                                  ];
                                }
                              "
                            >
                              <a-select-option
                                v-for="(item, index) in 15"
                                :key="item"
                                :value="item"
                              >
                                {{ formatStartOfQuestionRoman(index) }}
                              </a-select-option>
                            </a-select>
                          </div>
                        </div>
                      </template>
                      <a-card
                        size="small"
                        style="border: none; padding-top: 0px !important"
                        :class="
                          question.questionContent === '' &&
                          (question.questionType === 'True/False/Not Given' ||
                            question.questionType === 'Yes/No/Not Given' ||
                            question.questionType ===
                              'Multiple choice with one answer' ||
                            question.questionType ===
                              'Multiple choice with more than one answer')
                            ? 'set-margin-top'
                            : 'set-small-margin-top'
                        "
                      >
                        <!-- True/False/Not Given -->
                        <div
                          v-if="
                            question.questionType === 'True/False/Not Given'
                          "
                          style="width: 100%"
                        >
                          <div
                            class="edu-editor"
                            v-if="question.questionContent !== ''"
                            v-html="question.questionContentWithoutKey"
                          ></div>
                          <a-radio-group
                            style="width: 100%"
                            v-model="question.valueForRadio"
                            @change="
                              () => {
                                question.valueForRadio === 0
                                  ? (question.listKeys = ['True'])
                                  : question.valueForRadio === 1
                                  ? (question.listKeys = ['False'])
                                  : (question.listKeys = ['Not Given']);
                              }
                            "
                          >
                            <a-radio class="radio-style" :value="0">
                              <b> True </b>
                            </a-radio>

                            <a-radio class="radio-style" :value="1">
                              <b> False </b>
                            </a-radio>

                            <a-radio class="radio-style" :value="2">
                              <b> Not Given </b>
                            </a-radio>
                          </a-radio-group>
                        </div>
                        <!-- Yes/No/Not Given -->
                        <div
                          v-if="question.questionType === 'Yes/No/Not Given'"
                          style="width: 100%"
                        >
                          <div
                            v-if="question.questionContent !== ''"
                            class="edu-editor"
                            v-html="question.questionContentWithoutKey"
                          ></div>
                          <a-radio-group
                            style="width: 100%"
                            v-model="question.valueForRadio"
                            @change="
                              () => {
                                question.valueForRadio === 0
                                  ? (question.listKeys = ['Yes'])
                                  : question.valueForRadio === 1
                                  ? (question.listKeys = ['No'])
                                  : (question.listKeys = ['Not Given']);
                              }
                            "
                          >
                            <a-radio class="radio-style" :value="0">
                              <b> Yes </b>
                            </a-radio>

                            <a-radio class="radio-style" :value="1">
                              <b> No </b>
                            </a-radio>

                            <a-radio class="radio-style" :value="2">
                              <b> Not Given </b>
                            </a-radio>
                          </a-radio-group>
                        </div>
                        <!-- Summary Completion -->
                        <div
                          v-if="question.questionType === 'Summary Completion'"
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                        </div>
                        <!-- Multiple choice with one answer -->
                        <div
                          v-if="
                            question.questionType ===
                            'Multiple choice with one answer'
                          "
                        >
                          <div
                            class="edu-editor"
                            v-if="question.questionContent !== ''"
                            v-html="question.questionContentWithoutKey"
                          ></div>

                          <a-radio-group
                            v-model="question.valueForRadio"
                            v-for="(
                              answer, answerIndex
                            ) in question.listAnswers"
                            :key="answerIndex"
                            :value="answer"
                            @change="
                              (val) => {
                                question.listKeys = [
                                  question.listAnswers[question.valueForRadio],
                                ];
                              }
                            "
                            style="width: 100%"
                          >
                            <div>
                              <a-radio
                                class="radio-style-multiple"
                                :value="answerIndex"
                              >
                                <div style="display: flex; margin-top: -3px">
                                  <b>
                                    {{
                                      `${formatStartOfQuestion(answerIndex)} `
                                    }}
                                  </b>
                                  <span style="margin-left: 10px">{{
                                    answer
                                  }}</span>
                                </div>
                              </a-radio>
                            </div>
                          </a-radio-group>
                        </div>
                        <!-- Multiple choice with more than one answer -->
                        <div
                          v-if="
                            question.questionType ===
                            'Multiple choice with more than one answer'
                          "
                        >
                          <div
                            class="edu-editor"
                            v-if="question.questionContent !== ''"
                            v-html="question.questionContentWithoutKey"
                          ></div>

                          <div
                            v-for="(
                              answer, answerIndex
                            ) in question.listAnswers"
                            :key="answerIndex"
                            :value="answer"
                            style="
                              display: flex;
                              justify-content: start;
                              background: #f5f5f5;
                              border-radius: 5px;
                              margin-top: 0.5rem;
                            "
                          >
                            <div style="padding: 10px; display: flex">
                              <v-checkbox
                                @change="
                                    (val) =>
                                        handleAddAnswerKeyCheckBox(
                                        val,
                                        question,
                                        answerIndex
                                        ), 
                                    "
                                :disabled="
                                  question.listKeys.length ===
                                    question.totalKeys &&
                                  question.listKeys.indexOf(answer) === -1
                                "
                                style="height: 10px; margin-top: -5px"
                              >
                              </v-checkbox>
                            </div>
                            <div style="padding: 10px; max-width: 80%">
                              <b>
                                {{ `${formatStartOfQuestion(answerIndex)}. ` }}
                              </b>
                              <b>{{ answer }}</b>
                            </div>
                          </div>
                        </div>
                        <!-- Flow-chart Completion (image) -->
                        <div
                          v-if="
                            question.questionType ===
                            'Flow-chart Completion (image)'
                          "
                        >
                          <div
                            class="edu-editor"
                            v-html="question.questionContentWithoutKey"
                          ></div>

                          <div
                            v-for="(
                              answer, answerIndex
                            ) in question.listAnswers"
                            :key="answerIndex"
                            style="
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <div
                              style="
                                padding: 10px;
                                max-width: 80%;
                                display: flex;
                              "
                            >
                              <b style="padding-top: 0.3rem"
                                >{{ answerIndex + 1 }}.</b
                              >
                              <a-input
                                style="
                                  border: solid 1px;
                                  height: 1.5rem;
                                  margin: 5px;
                                  padding: 2px;
                                "
                                @change="
                                  (val) => {
                                    handleAddKey(
                                      val.target.value,
                                      question,
                                      answerIndex
                                    );
                                  }
                                "
                              >
                              </a-input>
                            </div>
                          </div>
                        </div>
                        <!-- Flow-chart Completion -->
                        <div
                          v-if="
                            question.questionType === 'Flow-chart Completion'
                          "
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                        </div>
                        <!-- Sentence Completion -->
                        <div
                          v-if="question.questionType === 'Sentence Completion'"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              :draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div style="padding: 10px; max-width: 80%">
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Flow-chart Completion (dragable) -->
                        <div
                          v-if="
                            question.questionType ===
                            'Flow-chart Completion (dragable)'
                          "
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              :draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div
                                style="
                                  padding: 10px;
                                  max-width: 80%;
                                  display: flex;
                                "
                              >
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Matching Headings -->
                        <div
                          v-if="question.questionType === 'Matching Headings'"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div
                                style="
                                  padding: 10px;
                                  max-width: 80%;
                                  display: flex;
                                "
                              >
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer.replace("[", "").replace("]", "")
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--  Matching Sentence Endings -->
                        <div
                          v-if="
                            question.questionType ===
                            'Matching Sentence Endings'
                          "
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              :draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div
                                style="
                                  padding: 10px;
                                  max-width: 80%;
                                  display: flex;
                                "
                              >
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Summary Completion (dragable) -->
                        <div
                          v-if="
                            question.questionType ===
                            'Summary Completion (dragable)'
                          "
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              :draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div
                                style="
                                  padding: 10px;
                                  max-width: 80%;
                                  display: flex;
                                "
                              >
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Map Labelling (dragable) -->
                        <div
                          v-if="
                            question.questionType === 'Map Labelling (dragable)'
                          "
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              :draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div
                                style="
                                  padding: 10px;
                                  max-width: 80%;
                                  display: flex;
                                "
                              >
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Table Completion -->
                        <div
                          v-if="question.questionType === 'Table Completion'"
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                        </div>
                        <!-- Form Completion -->
                        <div
                          v-if="question.questionType === 'Form Completion'"
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                        </div>
                        <!-- Note Completion -->

                        <div v-if="question.questionType === 'Note Completion'">
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                        </div>
                      </a-card>
                    </a-collapse-panel>
                  </a-collapse>
                </div>
              </div>
            </div>
          </div>
        </a-card>
        <div></div>
        <div
          v-bind:style="[
            topicIndex === currentIndex ? {} : { display: 'none' },
          ]"
          style="display: flex; width: 100%; height: 1.7rem"
        >
          <div style="width: 90%; display: flex">
            <a-checkbox
              style="display: flex; align-items: center"
              v-if="!studentCheckedKeys[currentButtonIndex].isReview"
              @change="handleCheckReview()"
            >
              review
            </a-checkbox>
            <a-checkbox
              style="display: flex; align-items: center"
              v-else
              @change="handleCheckReview()"
              :checked="true"
            >
              review
            </a-checkbox>
            <div
              style="
                width: 96%;
                padding: 0px;
                margin-left: 1rem;
                height: fix-content;
              "
            >
              <div v-if="studentCheckedKeys.length <= 40" style="display: flex">
                <div v-for="(item, index) in studentCheckedKeys" :key="index">
                  <div
                    v-if="
                      listStudentKeys &&
                      listStudentKeys[index] &&
                      listStudentKeys[index].key !== ''
                    "
                  >
                    <a-button
                      size="small"
                      style="margin-left: 4px; color: white; background: #000"
                      class="btn_style"
                      type="primary"
                      v-if="!item.isReview"
                      @click="handleReview(index)"
                    >
                      {{ index + 1 }}
                    </a-button>
                    <a-button
                      size="small"
                      style="margin-left: 4px; color: white; background: #000"
                      type="primary"
                      shape="circle"
                      class="btn_style"
                      v-if="item.isReview"
                      @click="handleReview(index)"
                    >
                      {{ index + 1 }}
                    </a-button>
                  </div>
                  <div v-else>
                    <a-button
                      size="small"
                      style="margin-left: 4px"
                      v-if="item.isReview"
                      shape="circle"
                      @click="handleReview(index)"
                    >
                      {{ index + 1 }}
                    </a-button>
                    <a-button
                      @click="handleReview(index)"
                      size="small"
                      style="margin-left: 4px"
                      v-else
                    >
                      {{ index + 1 }}
                    </a-button>
                  </div>
                </div>
              </div>
              <div v-else>
                <div style="display: flex">
                  <div v-for="(x, index) in 20" :key="x">
                    <div
                      v-if="
                        listStudentKeys &&
                        listStudentKeys[index] &&
                        listStudentKeys[index].key !== ''
                      "
                    >
                      <a-button
                        size="small"
                        style="margin-left: 4px; color: white"
                        type="primary"
                        shape="circle"
                        v-if="studentCheckedKeys[index].isReview"
                        @click="handleReview(index)"
                      >
                        {{ x }}
                      </a-button>
                      <a-button
                        size="small"
                        style="margin-left: 4px; color: white"
                        type="primary"
                        v-else
                        @click="handleReview(index)"
                      >
                        {{ x }}
                      </a-button>
                    </div>
                    <div v-else>
                      <a-button
                        size="small"
                        style="margin-left: 4px"
                        shape="circle"
                        v-if="studentCheckedKeys[index].isReview"
                        @click="handleReview(index)"
                      >
                        {{ x }}
                      </a-button>
                      <a-button
                        @click="handleReview(index)"
                        size="small"
                        style="margin-left: 4px"
                        v-else
                      >
                        {{ x }}
                      </a-button>
                    </div>
                  </div>
                </div>
                <div style="display: flex; margin-top: 0.5rem">
                  <div
                    v-for="(j, index) in studentCheckedKeys.length - 20"
                    :key="j"
                  >
                    <div
                      v-if="
                        listStudentKeys &&
                        listStudentKeys[index + 20] &&
                        listStudentKeys[index + 20].key !== ''
                      "
                    >
                      <a-button
                        size="small"
                        style="margin-left: 4px; color: white"
                        type="primary"
                        v-if="!studentCheckedKeys[index + 25].isReview"
                        @click="handleReview(index + 20)"
                      >
                        {{ j + 20 }}
                      </a-button>
                      <a-button
                        size="small"
                        style="margin-left: 4px; color: white"
                        shape="circle"
                        type="primary"
                        v-else
                        @click="handleReview(index + 20)"
                      >
                        {{ j + 20 }}
                      </a-button>
                    </div>
                    <div v-else>
                      <a-button
                        shape="circle"
                        size="small"
                        style="margin-left: 4px"
                        v-if="studentCheckedKeys[index + 20].isReview"
                        @click="handleReview(index + 20)"
                      >
                        {{ j + 20 }}
                      </a-button>
                      <a-button
                        @click="handleReview(index + 25)"
                        size="small"
                        style="margin-left: 4px"
                        v-else
                      >
                        {{ j + 20 }}
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: end;
              position: absolute;
              right: 10px;
              bottom: 5px;
            "
            class="responsive-button-submit"
          >
            <a-button
              type="primary"
              v-if="currentIndex > 0 && currentIndex <= totalTopics"
              style="height: 2rem; background: #000"
              @click="handleChangePage(topicIndex - 1)"
            >
              <a-icon type="arrow-left" />
            </a-button>
            <a-button
              style="margin-left: 0.5rem; height: 2rem; background: #000"
              type="primary"
              v-if="currentIndex >= 0 && currentIndex < totalTopics"
              @click="handleChangePage(topicIndex + 1)"
            >
              <a-icon type="arrow-right" />
            </a-button>
            <!-- <a-button
                style="margin-left: 0.5rem; height: 2rem; color: white"
                v-if="currentIndex === totalTopics"
                type="primary"
                @click="handleSubmit"
              >
                Submit</a-button
              > -->
            <v-btn
              style="
                margin-left: 0.5rem;
                height: 2rem;
                color: white;
                background: black;
              "
              v-if="currentIndex === totalTopics"
              type="primary"
              @click="handleBackToClassDetail"
            >
              <!-- <img style="width :20px; margin-right :1rem" src="../../../assets/send.png" /> -->
              Back
              <v-icon dark right> mdi-send </v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <TableLoading v-if="loading"></TableLoading>
      <a-card
        v-else
        style="margin-top: 40px"
        class="remove-card-body-padding responsive-listening-site-header"
      >
        <div
          style="display: flex; align-items: center"
          class="responsive-listening-site-header-item"
        >
          <audio
            type="audio/mpeg"
            id="audioMain"
            controls
            class="custom-audio"
            autoplay
            controlsList="nodownload noplaybackrate"
            :src="fileListeningUrl"
          >
            <!-- <source :src="fileListeningUrl" type="audio/mpeg" /> -->
          </audio>
          <div
            id="slider-main"
            class="slider-main"
            style="display: flex; width: 340px; align-items: center"
          >
            <label
              for="pbrate"
              style="margin: 0px !important; margin-right: 0.8rem"
              >Speed:</label
            >
            <input
              type="range"
              id="pbrate"
              min="0.5"
              max="3"
              value="1"
              step=".25"
            />
            <span style="margin-left: 0.8rem" id="spanMain"></span>
          </div>
          <div
            style="
              position: absolute;
              right: 10%;
              z-index: 100;
              top: 20%;
              display: flex;
              align-items: center;
            "
          >
            <b style="font-size: 13px">A</b>
            <input
              style="margin-left: 5px; margin-right: 5px"
              @change="(val) => handleChangeFontSize(val)"
              class="range-slider__range"
              type="range"
              value="14"
              min="8"
              max="25"
            />

            <b style="font-size: 20px">A</b>
          </div>
          <a-divider
            type="vertical"
            style="position: absolute; top: 10%; right: 8.5%; height: 39px"
          />
          <a-statistic-countdown
            class="responsive-countdown"
            v-if="countDown"
            :value="countDown"
            :format="isShowRedCountDown ? 's' : 'HH:mm:ss'"
            :valueStyle="{ color: isShowRedCountDown ? 'red' : 'inherit' }"
            :class="{ 'animate-red': isShowRedCountDown }"
            @finish="onFinish"
            style="position: absolute; right: 0.5%; z-index: 100; top: 13%"
          />
        </div>
      </a-card>
      <div v-if="!loading" class="listening-site listening-site-responsive">
        <div class="test-left" style="margin-top: 6.5rem">
          <div v-for="(topic, topicIndex) in listTopics" :key="topicIndex">
            <div
              style="
                padding: 10px;
                max-width: 100%;
                width: 100%;
                max-height: 100%;
                overflow-y: scroll;
              "
            >
              <h4 style="margin: unset">Part {{ topicIndex + 1 }}</h4>
              <div class="edu-editor" v-html="topic.description"></div>
              <a-button
                v-if="topic && topic.fileListening"
                size="default"
                class="btn_style"
                style="height: 1.5rem; margin: 0rem 0 1rem 0"
                @click="handleChangeAudioSource(topic.fileListening)"
              >
                <div style="display: flex; align-items: center">
                  <img
                    style="width: 10px; margin-right: 0.7rem"
                    src="../../../assets/icons/headphones.png"
                  />
                  <span>Listen from here </span>
                </div>
              </a-button>
              <div
                v-for="(
                  questionGroup, questionGroupIndex
                ) in topic.listQuestionGroups"
                :key="questionGroupIndex"
                style="margin-bottom: 1rem"
              >
                <div>
                  <div
                    v-if="!questionGroup.content.includes('<figure')"
                    v-html="questionGroup.content"
                    style="margin-bottom: -0.5rem"
                  ></div>
                  <ckeditor
                    v-else
                    :id="makeid(5)"
                    :editor="editor"
                    @ready="onReady"
                    :config="editorConfig"
                    v-model="questionGroup.content"
                  ></ckeditor>
                  <a-collapse
                    :bordered="false"
                    v-for="(
                      question, questionIndex
                    ) in questionGroup.listQuestions"
                    :key="questionIndex + 1"
                    :value="question"
                    @change="
                      handleCollapse(questionGroup, question, questionIndex)
                    "
                  >
                    <template #expandIcon="props">
                      <a-icon
                        style="padding-bottom: 2px"
                        type="caret-right"
                        :rotate="props.isActive ? 90 : 0"
                      />
                    </template>
                    <a-collapse-panel
                      :class="
                        handleCreateClassName(
                          topicIndex,
                          questionGroupIndex,
                          question,
                          questionIndex
                        )
                      "
                      style="background: white"
                      :key="questionIndex + 1"
                      :forceRender="true"
                      :disabled="
                        question.questionType === 'Matching Information' ||
                        question.questionType === 'Map Labelling (dropdown)'
                      "
                    >
                      <template slot="header">
                        <div
                          v-if="
                            question.questionType !== 'Matching Information' &&
                            question.questionType !== 'Map Labelling (dropdown)'
                          "
                          style="display: flex; padding-bottom: 0px"
                        >
                          <div style="display: flex; align-items: center">
                            <b
                              style="margin-right: 10px; max-width: 5rem"
                              v-bind:style="[
                                question.questionNumber.length > 2
                                  ? { width: '40px' }
                                  : {},
                              ]"
                              >{{ question.questionNumber }}</b
                            >
                          </div>

                          <span>{{ question.questionTitle }}</span>
                        </div>
                        <div v-else style="display: flex">
                          <b
                            style="
                              margin-right: 10px;
                              display: flex;
                              align-items: center;
                            "
                            >{{ question.questionNumber }}</b
                          >
                          <span
                            style="
                              width: 90%;
                              display: flex;
                              align-items: center;
                            "
                            >{{ question.questionTitle }}</span
                          >
                          <div>
                            <a-select
                              v-if="!question.isRomanNumber"
                              v-model="question.valueForRadio"
                              size="default"
                              style="width: 60px; height: 2rem"
                              @change="
                                (val) => {
                                  question.listKeys = [
                                    formatStartOfQuestion(val - 1),
                                  ];
                                }
                              "
                            >
                              <a-select-option
                                v-for="(item, index) in 27"
                                :key="item"
                                :value="item"
                              >
                                {{ formatStartOfQuestion(index) }}
                              </a-select-option>
                            </a-select>
                            <a-select
                              v-else
                              size="default"
                              style="width: 60px; height: 2rem"
                              v-model="question.valueForRadio"
                              @change="
                                (val) => {
                                  question.listKeys = [
                                    formatStartOfQuestionRoman(val - 1),
                                  ];
                                }
                              "
                            >
                              <a-select-option
                                v-for="(item, index) in 15"
                                :key="item"
                                :value="item"
                              >
                                {{ formatStartOfQuestionRoman(index) }}
                              </a-select-option>
                            </a-select>
                          </div>
                        </div>
                      </template>
                      <a-card
                        size="small"
                        style="border: none; padding-top: 0px !important"
                        :class="
                          question.questionContent === '' &&
                          (question.questionType === 'True/False/Not Given' ||
                            question.questionType === 'Yes/No/Not Given' ||
                            question.questionType ===
                              'Multiple choice with one answer' ||
                            question.questionType ===
                              'Multiple choice with more than one answer')
                            ? 'set-margin-top'
                            : 'set-small-margin-top'
                        "
                      >
                        <!-- True/False/Not Given -->
                        <div
                          v-if="
                            question.questionType === 'True/False/Not Given'
                          "
                          style="width: 100%"
                        >
                          <div
                            class="edu-editor"
                            v-if="question.questionContent !== ''"
                            v-html="question.questionContentWithoutKey"
                          ></div>
                          <a-radio-group
                            style="width: 100%"
                            v-model="question.valueForRadio"
                            @change="
                              () => {
                                question.valueForRadio === 0
                                  ? (question.listKeys = ['True'])
                                  : question.valueForRadio === 1
                                  ? (question.listKeys = ['False'])
                                  : (question.listKeys = ['Not Given']);
                              }
                            "
                          >
                            <a-radio class="radio-style" :value="0">
                              <b> True </b>
                            </a-radio>

                            <a-radio class="radio-style" :value="1">
                              <b> False </b>
                            </a-radio>

                            <a-radio class="radio-style" :value="2">
                              <b> Not Given </b>
                            </a-radio>
                          </a-radio-group>
                        </div>
                        <!-- Yes/No/Not Given -->
                        <div
                          v-if="question.questionType === 'Yes/No/Not Given'"
                          style="width: 100%"
                        >
                          <div
                            v-if="question.questionContent !== ''"
                            class="edu-editor"
                            v-html="question.questionContentWithoutKey"
                          ></div>
                          <a-radio-group
                            style="width: 100%"
                            v-model="question.valueForRadio"
                            @change="
                              () => {
                                question.valueForRadio === 0
                                  ? (question.listKeys = ['Yes'])
                                  : question.valueForRadio === 1
                                  ? (question.listKeys = ['No'])
                                  : (question.listKeys = ['Not Given']);
                              }
                            "
                          >
                            <a-radio class="radio-style" :value="0">
                              <b> Yes </b>
                            </a-radio>

                            <a-radio class="radio-style" :value="1">
                              <b> No </b>
                            </a-radio>
                            <a-radio class="radio-style" :value="2">
                              <b> Not Given </b>
                            </a-radio>
                          </a-radio-group>
                        </div>
                        <!-- Summary Completion -->
                        <div
                          v-if="question.questionType === 'Summary Completion'"
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                        </div>
                        <!-- Multiple choice with one answer -->
                        <div
                          v-if="
                            question.questionType ===
                            'Multiple choice with one answer'
                          "
                        >
                          <div
                            class="edu-editor"
                            v-if="question.questionContentWithoutKey !== ''"
                            v-html="question.questionContentWithoutKey"
                          ></div>

                          <a-radio-group
                            v-model="question.valueForRadio"
                            v-for="(
                              answer, answerIndex
                            ) in question.listAnswers"
                            :key="answerIndex"
                            :value="answer"
                            @change="
                              (val) => {
                                question.listKeys = [
                                  question.listAnswers[question.valueForRadio],
                                ];
                              }
                            "
                            style="width: 100%"
                          >
                            <div>
                              <a-radio
                                class="radio-style-multiple"
                                :value="answerIndex"
                              >
                                <div style="display: flex; margin-top: -3px">
                                  <b>
                                    {{
                                      `${formatStartOfQuestion(answerIndex)} `
                                    }}
                                  </b>
                                  <span style="margin-left: 10px">{{
                                    answer
                                  }}</span>
                                </div>
                              </a-radio>
                            </div>
                          </a-radio-group>
                        </div>
                        <!-- Multiple choice with more than one answer -->
                        <div
                          v-if="
                            question.questionType ===
                            'Multiple choice with more than one answer'
                          "
                        >
                          <div
                            class="edu-editor"
                            v-if="question.questionContent !== ''"
                            v-html="question.questionContentWithoutKey"
                          ></div>

                          <div
                            v-for="(
                              answer, answerIndex
                            ) in question.listAnswers"
                            :key="answerIndex"
                            :value="answer"
                            style="
                              display: flex;
                              justify-content: start;
                              background: #f5f5f5;
                              border-radius: 5px;
                              margin-top: 0.5rem;
                            "
                          >
                            <div style="padding: 10px; display: flex">
                              <v-checkbox
                                @change="
                                    (val) =>
                                        handleAddAnswerKeyCheckBox(
                                        val,
                                        question,
                                        answerIndex
                                        ), 
                                    "
                                :disabled="
                                  question.listKeys.length ===
                                    question.totalKeys &&
                                  question.listKeys.indexOf(answer) === -1
                                "
                                style="height: 10px; margin-top: -5px"
                              >
                              </v-checkbox>
                            </div>
                            <div style="padding: 10px; max-width: 80%">
                              <b>
                                {{ `${formatStartOfQuestion(answerIndex)}. ` }}
                              </b>
                              <b>{{ answer }}</b>
                            </div>
                          </div>
                        </div>
                        <!-- Flow-chart Completion (image) -->
                        <div
                          v-if="
                            question.questionType ===
                            'Flow-chart Completion (image)'
                          "
                        >
                          <div
                            class="edu-editor"
                            v-html="question.questionContentWithoutKey"
                          ></div>

                          <div
                            v-for="(
                              answer, answerIndex
                            ) in question.listAnswers"
                            :key="answerIndex"
                            style="
                              display: flex;
                              justify-content: space-between;
                            "
                          >
                            <div
                              style="
                                padding: 10px;
                                max-width: 80%;
                                display: flex;
                              "
                            >
                              <b style="padding-top: 0.3rem"
                                >{{ answerIndex + 1 }}.</b
                              >
                              <a-input
                                style="
                                  border: solid 1px;
                                  height: 1.5rem;
                                  margin: 5px;
                                  padding: 2px;
                                "
                                @change="
                                  (val) => {
                                    handleAddKey(
                                      val.target.value,
                                      question,
                                      answerIndex
                                    );
                                  }
                                "
                              >
                              </a-input>
                            </div>
                          </div>
                        </div>
                        <!-- Flow-chart Completion -->
                        <div
                          v-if="
                            question.questionType === 'Flow-chart Completion'
                          "
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                        </div>
                        <!-- Sentence Completion -->
                        <div
                          v-if="question.questionType === 'Sentence Completion'"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              :draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div style="padding: 10px; max-width: 80%">
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Flow-chart Completion (dragable) -->
                        <div
                          v-if="
                            question.questionType ===
                            'Flow-chart Completion (dragable)'
                          "
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              :draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div
                                style="
                                  padding: 10px;
                                  max-width: 80%;
                                  display: flex;
                                "
                              >
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Matching Headings -->
                        <div
                          v-if="question.questionType === 'Matching Headings'"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div
                                style="
                                  padding: 10px;
                                  max-width: 80%;
                                  display: flex;
                                "
                              >
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer.replace("[", "").replace("]", "")
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--  Matching Sentence Endings -->
                        <div
                          v-if="
                            question.questionType ===
                            'Matching Sentence Endings'
                          "
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              :draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div
                                style="
                                  padding: 10px;
                                  max-width: 80%;
                                  display: flex;
                                "
                              >
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Summary Completion (dragable) -->
                        <div
                          v-if="
                            question.questionType ===
                            'Summary Completion (dragable)'
                          "
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              :draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div
                                style="
                                  padding: 10px;
                                  max-width: 80%;
                                  display: flex;
                                "
                              >
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Map Labelling (dragable) -->
                        <div
                          v-if="
                            question.questionType === 'Map Labelling (dragable)'
                          "
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                          <div
                            class="answer-list"
                            @dragover="
                              (event) => {
                                event.stopPropagation();
                                event.preventDefault();
                              }
                            "
                            @dragenter="
                              (event) => {
                                event.preventDefault();
                                handleChangeBorder();
                              }
                            "
                            @dragleave="
                              (event) => {
                                handleUnChangeBorder();
                              }
                            "
                            @drop="
                              (event) =>
                                dropToAnswersList(
                                  event,
                                  questionGroup,
                                  question
                                )
                            "
                          >
                            <div
                              v-for="(
                                answer, answerIndex
                              ) in question.listAnswersWithoutKey"
                              :key="answerIndex"
                              :value="answer"
                              style="
                                display: flex;
                                justify-content: space-between;
                                background: #f5f5f5;
                                border-radius: 5px;
                                margin-top: 0.5rem;
                              "
                              :draggable="true"
                              @dragstart="startDrag($event, answer)"
                              @dragend="
                                endDrag($event, questionGroup, question)
                              "
                            >
                              <div
                                style="
                                  padding: 10px;
                                  max-width: 80%;
                                  display: flex;
                                "
                              >
                                <b> {{ `${answerIndex + 1}. ` }} </b>
                                <b>{{
                                  answer
                                    ? answer
                                    : answer.replace("[", "").replace("]", "")
                                }}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Table Completion -->
                        <div
                          v-if="question.questionType === 'Table Completion'"
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                        </div>
                        <!-- Form Completion -->
                        <div
                          v-if="question.questionType === 'Form Completion'"
                          style="margin-top: 0.5rem"
                        >
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                        </div>
                        <!-- Note Completion -->

                        <div v-if="question.questionType === 'Note Completion'">
                          <div
                            class="edu-editor"
                            v-html="
                              replaceBracketWithInput(
                                question.questionContentWithoutKey
                              )
                            "
                          ></div>
                        </div>
                      </a-card>
                    </a-collapse-panel>
                  </a-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="test-right">
          <h5 style="margin-top: 1.5rem; text-align: center; color: black">
            {{ this.testName }}
          </h5>
          <a-divider style="margin-left: -0.1rem" />
          <h6 style="margin-left: 1rem; color: black; margin-bottom: 12px">
            Question Palette
          </h6>
          <div style="width: 100%; display: flex">
            <div
              style="
                width: 96%;
                padding: 0px;
                margin-left: 0.5rem;
                height: fix-content;
              "
            >
              <div style="display: flex; flex-wrap: wrap">
                <div v-for="(item, index) in studentCheckedKeys" :key="index">
                  <div
                    style="margin-top: 0.5rem"
                    v-if="
                      listStudentKeys &&
                      listStudentKeys[index] &&
                      listStudentKeys[index].key !== ''
                    "
                  >
                    <a-dropdown v-if="!item.isReview">
                      <a-button
                        size="default"
                        :class="
                          index > 8
                            ? 'resize-button-primary-number'
                            : 'normal-primary-button'
                        "
                        class="btn_style"
                        type="primary"
                        style="background: #000"
                        @click="handleReview(index)"
                      >
                        {{ index + 1 }}
                      </a-button>
                      <template #overlay>
                        <a-menu>
                          <a-menu-item>
                            <a @click="handleCheckReviewForListening(index)">
                              {{
                                studentCheckedKeys[index].isReview
                                  ? "UnReview"
                                  : "Review"
                              }}
                            </a>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>

                    <a-dropdown v-if="item.isReview">
                      <a-button
                        size="default"
                        :class="
                          index > 8
                            ? 'resize-button-primary-number'
                            : 'normal-primary-button'
                        "
                        type="primary"
                        style="background: #000"
                        shape="circle"
                        class="btn_style"
                        @click="handleReview(index)"
                      >
                        {{ index + 1 }}
                      </a-button>

                      <template #overlay>
                        <a-menu>
                          <a-menu-item>
                            <a @click="handleCheckReviewForListening(index)">
                              {{
                                studentCheckedKeys[index].isReview
                                  ? "UnReview"
                                  : "Review"
                              }}
                            </a>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </div>
                  <div style="margin-top: 0.5rem" v-else>
                    <a-dropdown v-if="item.isReview">
                      <a-button
                        size="default"
                        :class="
                          index > 8 ? 'resize-button-number' : 'normal-button'
                        "
                        shape="circle"
                        @click="handleReview(index)"
                      >
                        {{ index + 1 }}
                      </a-button>

                      <template #overlay>
                        <a-menu>
                          <a-menu-item>
                            <a @click="handleCheckReviewForListening(index)">
                              {{
                                studentCheckedKeys[index].isReview
                                  ? "UnReview"
                                  : "Review"
                              }}
                            </a>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>

                    <a-dropdown v-else>
                      <a-button
                        :class="
                          index > 8 ? 'resize-button-number' : 'normal-button'
                        "
                        @click="handleReview(index)"
                        size="default"
                      >
                        {{ index + 1 }}
                      </a-button>

                      <template #overlay>
                        <a-menu>
                          <a-menu-item>
                            <a @click="handleCheckReviewForListening(index)">
                              {{
                                studentCheckedKeys[index].isReview
                                  ? "UnReview"
                                  : "Review"
                              }}
                            </a>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="type-of-answer">
            <div class="type-of-answer-item">
              <div class="need-review-answer-container">
                <div class="normal-answered"></div>
                <p class="need-review-answer-label">Answered</p>
              </div>
              <div
                style="margin-left: 3rem"
                class="need-review-answer-container"
              >
                <div class="need-review-answered"></div>
                <p class="need-review-answer-label">Answered need to review</p>
              </div>
            </div>
            <div class="type-of-answer-item">
              <div class="need-review-answer-container">
                <div class="normal-answer"></div>
                <p class="need-review-answer-label">Answer</p>
              </div>
              <div
                style="margin-left: 4rem"
                class="need-review-answer-container"
              >
                <div class="need-review-answer"></div>
                <p class="need-review-answer-label">Answer need to review</p>
              </div>
            </div>
          </div>
          <div class="submit-btn">
            <v-btn
              color="primary"
              dark
              style="margin-right: 10px"
              @click="handleSubmit"
            >
              <!-- <img style="width :20px; margin-right :1rem" src="../../../assets/send.png" /> -->
              Back
              <v-icon dark right> mdi-send </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="test-right-for-mobile">
          <a-collapse style="color: white" expand-icon-position="right">
            <template #expandIcon="props">
              <a-icon
                style="padding-bottom: 2px; color: white"
                type="caret-right"
                :rotate="props.isActive ? 90 : -90"
              />
            </template>

            <a-collapse-panel
              class="responsive-content-box"
              style="background: #edd31d; color: white"
              key="1"
            >
              <template slot="header">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b style="color: black; font-size: 18px">Question Palette </b>
                  <a-statistic-countdown
                    v-if="countDown"
                    :value="countDown"
                    class="responsive-countdown-mobile"
                    format="HH:mm:ss"
                    @finish="onFinish"
                    style="color: white"
                  />
                </div>
              </template>
              <div
                class="responsive-content-collapse"
                style="background: #edd31d"
              >
                <h5
                  style="
                    color: black;
                    font-size: 18px;
                    padding-top: 0.5rem;
                    text-align: center;
                  "
                >
                  {{ this.testName }}
                </h5>
                <div style="width: 90%; display: flex">
                  <div
                    style="
                      width: 96%;
                      padding: 0px;
                      margin-left: 0.5rem;
                      height: fix-content;
                    "
                  >
                    <div style="display: flex; flex-wrap: wrap">
                      <div
                        v-for="(item, index) in studentCheckedKeys"
                        :key="index"
                      >
                        <div
                          style="margin-top: 0.5rem"
                          v-if="
                            listStudentKeys &&
                            listStudentKeys[index] &&
                            listStudentKeys[index].key !== ''
                          "
                        >
                          <a-dropdown>
                            <a-button
                              size="default"
                              :class="
                                index > 8
                                  ? 'resize-button-primary-number'
                                  : 'normal-primary-button'
                              "
                              class="btn_style"
                              type="primary"
                              style="background: #000"
                              v-if="!item.isReview"
                              @click="handleReview(index)"
                            >
                              {{ index + 1 }}
                            </a-button>
                            <template #overlay>
                              <a-menu>
                                <a-menu-item>
                                  <a
                                    @click="
                                      handleCheckReviewForListening(index)
                                    "
                                  >
                                    {{
                                      studentCheckedKeys[index].isReview
                                        ? "UnReview"
                                        : "Review"
                                    }}
                                  </a>
                                </a-menu-item>
                              </a-menu>
                            </template>
                          </a-dropdown>

                          <a-dropdown>
                            <a-button
                              size="default"
                              :class="
                                index > 8
                                  ? 'resize-button-primary-number'
                                  : 'normal-primary-button'
                              "
                              type="primary"
                              style="background: #000"
                              shape="circle"
                              class="btn_style"
                              v-if="item.isReview"
                              @click="handleReview(index)"
                            >
                              {{ index + 1 }}
                            </a-button>

                            <template #overlay>
                              <a-menu>
                                <a-menu-item>
                                  <a
                                    @click="
                                      handleCheckReviewForListening(index)
                                    "
                                  >
                                    {{
                                      studentCheckedKeys[index].isReview
                                        ? "UnReview"
                                        : "Review"
                                    }}
                                  </a>
                                </a-menu-item>
                              </a-menu>
                            </template>
                          </a-dropdown>
                        </div>
                        <div style="margin-top: 0.5rem" v-else>
                          <a-dropdown v-if="item.isReview">
                            <a-button
                              size="default"
                              :class="
                                index > 8
                                  ? 'resize-button-number'
                                  : 'normal-button'
                              "
                              shape="circle"
                              @click="handleReview(index)"
                            >
                              {{ index + 1 }}
                            </a-button>

                            <template #overlay>
                              <a-menu>
                                <a-menu-item>
                                  <a
                                    @click="
                                      handleCheckReviewForListening(index)
                                    "
                                  >
                                    {{
                                      studentCheckedKeys[index].isReview
                                        ? "UnReview"
                                        : "Review"
                                    }}
                                  </a>
                                </a-menu-item>
                              </a-menu>
                            </template>
                          </a-dropdown>

                          <a-dropdown v-else>
                            <a-button
                              :class="
                                index > 8
                                  ? 'resize-button-number'
                                  : 'normal-button'
                              "
                              @click="handleReview(index)"
                              size="default"
                            >
                              {{ index + 1 }}
                            </a-button>

                            <template #overlay>
                              <a-menu>
                                <a-menu-item>
                                  <a
                                    @click="
                                      handleCheckReviewForListening(index)
                                    "
                                  >
                                    {{
                                      studentCheckedKeys[index].isReview
                                        ? "UnReview"
                                        : "Review"
                                    }}
                                  </a>
                                </a-menu-item>
                              </a-menu>
                            </template>
                          </a-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="type-of-answer">
                  <div class="type-of-answer-item">
                    <div class="need-review-answer-container">
                      <div class="normal-answered"></div>
                      <p class="need-review-answer-label">Answered</p>
                    </div>
                    <div
                      style="margin-left: 3rem"
                      class="need-review-answer-container"
                    >
                      <div class="need-review-answered"></div>
                      <p class="need-review-answer-label">
                        Answered need to review
                      </p>
                    </div>
                  </div>
                  <div class="type-of-answer-item">
                    <div class="need-review-answer-container">
                      <div class="normal-answer"></div>
                      <p class="need-review-answer-label">Answer</p>
                    </div>
                    <div
                      style="margin-left: 4rem"
                      class="need-review-answer-container"
                    >
                      <div class="need-review-answer"></div>
                      <p class="need-review-answer-label">
                        Answer need to review
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    text-align: end;
                    margin-top: 0.5rem;
                    padding-bottom: 0.5rem;
                  "
                >
                  <v-btn
                    color="primary"
                    dark
                    style="margin-right: 10px"
                    @click="handleBackToClassDetail"
                  >
                    <!-- <img style="width :20px; margin-right :1rem" src="../../../assets/send.png" /> -->
                    Back
                    <v-icon dark right> mdi-send </v-icon>
                  </v-btn>
                </div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MyUploadAdapter } from "../../Lesson/AddLessonModal.vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import { NotificationMixin } from "../../../mixin/Notification";
import StudentKeys from "../../../api/studentKey";
import * as _ from "lodash";
import store from "../../../store/index";
import { cloneDeep } from "lodash";
import Test from "../../../api/Test";
import Class from "../../../api/Class";
import TableLoading from "../../../components/Base/TableLoading.vue";
import QuestionType from "../../../api/QuestionType";
export default {
  mixins: [NotificationMixin],
  components: { TableLoading, ckeditor: CKEditor.component },
  data() {
    return {
      testName: "",
      visibleSendKey: false,
      sendKeyLoading: false,
      visibleLeave: false,
      isLeave: false,
      testDescription: "",
      totalQuestions: 0,
      listTopics: [],
      noPermission: false,
      activeKey: 1,
      listStudentKeys: [],
      value1: 30,
      isShowPoint: false,
      fileListeningUrl: "",
      visibleBackToExercise: false,
      studentResult: {},
      currentIndex: 0,
      totalTopics: 0,
      countDown: null,
      loading: false,
      studentCheckedKeys: [],
      currentButtonIndex: 0,
      defaultCheckedValue: true,
      isEditing: false,
      currentTotalQuestion: 0,
      currentStudentKeyId: null,
      currentSubject: "",
      testType: "",
      listInputCloned: [],
      interval: null,
      editorConfig: {
        toolbar: {},
        readOnly: true,
        contenteditable: false,
      },
      isShowRedCountDown: false,
      innerStudentKeycolumns: [
        {
          title: "Question",
          dataIndex: "",
          key: "index",
          scopedSlots: { customRender: "index" },
        },

        {
          title: "Your Answer",
          dataIndex: "",
          key: "key",
          scopedSlots: { customRender: "key" },
        },
        {
          title: "Key",
          dataIndex: "",
          key: "testKey",
          scopedSlots: { customRender: "testKey" },
        },
        {
          title: "Question Type",
          dataIndex: "",
          key: "questionType",
          scopedSlots: { customRender: "questionType" },
        },
      ],
    };
  },
  beforeUpdate() {
    this.$nextTick(function () {
      let collapse = document.getElementsByClassName("ant-collapse-header");
      if (collapse && collapse.length && collapse.length > 0) {
        for (let i = 0; i < collapse.length; i++) {
          if (
            !Array.from(collapse[i].parentNode.parentNode.classList).includes(
              "remove-collapse"
            )
          ) {
            collapse[i].setAttribute("aria-expanded", "true");
          }
        }
      }
      let collapseContent = document.getElementsByClassName(
        "ant-collapse-content"
      );
      if (
        collapseContent &&
        collapseContent.length &&
        collapseContent.length > 0
      ) {
        for (let j = 0; j < collapseContent.length; j++) {
          //auto collapse remove with questionType TRUE/FALSE/NOTGIVEN + YES/NO/NOTGIVEN
          if (
            !Array.from(
              collapseContent[j].parentNode.parentNode.classList
            ).includes("remove-collapse")
          ) {
            collapseContent[j].style.display = "block";
          }
        }
      }
    });
  },
  beforeDestroy() {
    const elements = document.getElementsByClassName("high-light-container");
    const stickyElement = document.getElementsByClassName(
      "sticky-note-container"
    );
    elements.forEach((el) => el.remove());
    stickyElement.forEach((el) => el.remove());
  },
  mounted() {
    Class.getClassById(this.$route.params.id)
      .then((response) => {
        const listStudentId = response.data.data.students.map(
          (item) => item._id
        );
        if (
          this.userProfile.role === "student" &&
          !listStudentId.includes(this.userProfile.id)
        ) {
          console.log("in");
          this.noPermission = true;
          this.$router.push("/dashboard");

          return;
        }
      })
      .catch((e) => {
        console.log("e", e);
      });
    document.getElementById("container").addEventListener("mouseup", (evt) => {
      const el = evt.currentTarget;
      const selObj = window.getSelection();

      if (!selObj.rangeCount) return;

      let range = selObj.getRangeAt(0);

      const parentElement = range.commonAncestorContainer.parentElement;
      if (
        parentElement.tagName !== "STRONG" &&
        parentElement.tagName !== "P" &&
        parentElement.tagName !== "SPAN" &&
        parentElement.tagName !== "B"
      )
        return;
      const currentHighlight = document.getElementById("high-light-div");
      if (currentHighlight) {
        currentHighlight.remove();
      }

      const selectedContent = selObj.toString();
      if (selectedContent.trim() !== "") {
        let rect = selObj.getRangeAt(0).getBoundingClientRect();
        let div = document.createElement("div");
        div.style.top = `${rect.top + window.scrollY - 50}px`;
        div.style.left = `${
          rect.left + window.scrollX + rect.width / 2 - 40
        }px`;
        div.setAttribute("class", "high-light-container");
        div.setAttribute("id", "high-light-div");

        const insertHighlight = (color) => {
          const highlightDiv = document.createElement("span");
          highlightDiv.setAttribute(
            "style",
            `background-color: ${color}; display: inline; white-space: pre-wrap;`
          );
          let text = range.toString();
          // Preserve spaces at the start and end
          if (
            range.startContainer.nodeType === Node.TEXT_NODE &&
            range.startOffset > 0
          ) {
            const prevChar =
              range.startContainer.textContent[range.startOffset - 1];
            if (prevChar !== " ") {
              const spaceNode = document.createTextNode("");
              range.insertNode(spaceNode);
              range.setStartAfter(spaceNode);
            }
          }

          range.deleteContents();
          range.insertNode(highlightDiv);
          const parent = highlightDiv.parentNode;
          if (parent && parent.nodeType === Node.ELEMENT_NODE) {
            const parentStyle = window.getComputedStyle(parent);
            if (parentStyle.whiteSpace !== "pre-wrap") {
              parent.style.whiteSpace = "pre-wrap";
            }
          }
          highlightDiv.textContent = text;

          selObj.removeAllRanges(); // Clear the selection after highlighting
        };

        let fill1 = document.createElement("img");
        fill1.setAttribute(
          "src",
          "https://firebasestorage.googleapis.com/v0/b/tretrauquiz.appspot.com/o/image%2Ffill1.png?alt=media&token=a5a06a94-0f70-4135-8f82-8e8f5e3b5511"
        );
        fill1.setAttribute("style", "width : 36px !important");
        fill1.setAttribute("class", "high-light-btn");
        fill1.addEventListener("click", () => insertHighlight("#c76478")); // Pink color

        let fill2 = document.createElement("img");
        fill2.setAttribute(
          "src",
          "https://firebasestorage.googleapis.com/v0/b/tretrauquiz.appspot.com/o/image%2Ffill2.png?alt=media&token=1de5c0e4-e194-432a-bf0c-0b730b003c82"
        );
        fill2.setAttribute("style", "width : 36px !important");
        fill2.setAttribute("class", "high-light-btn");
        fill2.addEventListener("click", () => insertHighlight("#57b972")); // Green color

        let fill3 = document.createElement("img");
        fill3.setAttribute(
          "src",
          "https://firebasestorage.googleapis.com/v0/b/tretrauquiz.appspot.com/o/image%2Ffill3.png?alt=media&token=0e74ea5f-2797-4c96-9f74-93ed00915787"
        );
        fill3.setAttribute("style", "width : 36px !important");
        fill3.setAttribute("class", "high-light-btn");
        fill3.addEventListener("click", () => insertHighlight("#32b4c7")); // Blue color

        let fillDelete = document.createElement("img");
        fillDelete.setAttribute(
          "src",
          "https://firebasestorage.googleapis.com/v0/b/tretrauquiz.appspot.com/o/image%2Ffill_delete.png?alt=media&token=97050f29-2351-4346-8d16-b4d25168e72b"
        );
        fillDelete.setAttribute("style", "width : 36px !important");
        fillDelete.setAttribute("class", "high-light-btn");
        fillDelete.addEventListener("click", () => {
          const parent = range.commonAncestorContainer.parentNode;
          if (parent && parent.style.backgroundColor) {
            parent.replaceWith(...parent.childNodes); // Remove the highlight
            selObj.removeAllRanges();
          }
        });

        div.appendChild(fill1);
        div.appendChild(fill2);
        div.appendChild(fill3);
        div.appendChild(fillDelete);

        document.body.appendChild(div);
      }
    });

    const drapDropTouch = document.createElement("script");
    drapDropTouch.setAttribute("src", "DragDropTouch.js");
    document.head.appendChild(drapDropTouch);
    this.loading = true;
    let listInputs;
    this.listInputCloned = cloneDeep(listInputs);
    setTimeout(() => {
      this.listTopics.map((topic, topicIndex) => {
        topic.listQuestionGroups.map((questionGroup, questionGroupIndex) => {
          listInputs = document.getElementsByClassName("answerTextBox");
          if (listInputs) {
            questionGroup.listInputs = listInputs;
            questionGroup.listKeys = [];
            for (let i = 0; i < questionGroup.listInputs.length; i++) {
              questionGroup.listKeys.push("");
            }
            questionGroup.oldKey = "";
            for (let j = 0; j < listInputs.length; j++) {
              // listInputs[i].addEventListener("drop", this.onDrop(event, index));
              let listInputsClone = listInputs[j].cloneNode(true);
              listInputs[j].replaceWith(listInputsClone);
              listInputs[j].addEventListener("focus", function (val) {
                questionGroup.listKeys[j] = val.target.value;
                store.commit("SET_SEND_TEST_STATUS", true);
              });
              listInputs[j].addEventListener("change", function (val) {
                questionGroup.listKeys[j] = val.target.value;
                store.commit("SET_SEND_TEST_STATUS", true);
              });
              listInputs[j].addEventListener("dragenter", function () {
                event.preventDefault();
              });
              listInputs[j].addEventListener("dragover", function () {
                event.stopPropagation();
                event.preventDefault();
              });
              listInputs[j].addEventListener("drop", function () {
                event.stopPropagation();
                event.preventDefault();
                listInputs[j].value = "";
                const b = document.createElement("b");
                questionGroup.oldKey = "";
                b.innerText = event.dataTransfer.getData("answerContent");
                b.setAttribute("draggable", true);
                // b.setAttribute("class", "answerTextBox");
                b.addEventListener("dragstart", function () {
                  event.dataTransfer.dropEffect = "move";
                  event.dataTransfer.effectAllowed = "move";
                  questionGroup.oldKey = b.innerText;
                  event.dataTransfer.setData("answerContent", b.innerText);
                });
                b.addEventListener("dragend", function () {
                  if (questionGroup.oldKey !== "") {
                    b.innerText = questionGroup.oldKey;
                    questionGroup.oldKey = "";
                  } else {
                    listInputs[j].value = "";
                    listInputs[j].style.display = "inherit";
                    questionGroup.listKeys[j] = "";
                    b.remove();
                  }
                  store.commit("SET_SEND_TEST_STATUS", true);
                });
                b.addEventListener("dragenter", function () {
                  event.preventDefault();
                });
                b.addEventListener("dragleave", function () {
                  event.preventDefault();
                });
                b.addEventListener("dragover", function () {
                  event.stopPropagation();
                  event.preventDefault();
                });
                b.addEventListener("drop", function () {
                  event.stopPropagation();
                  event.preventDefault();
                  b.innerText = event.dataTransfer.getData("answerContent");
                  if (
                    event.dataTransfer.getData("answerContent") !==
                    questionGroup.listKeys[j]
                  ) {
                    questionGroup.oldKey = questionGroup.listKeys[j];
                  }

                  questionGroup.listKeys[j] =
                    event.dataTransfer.getData("answerContent");
                  // event.dataTransfer.setData("answerContent", "");
                });
                listInputs[j].after(b);
                questionGroup.listKeys[j] =
                  event.dataTransfer.getData("answerContent");
                listInputs[j].value =
                  event.dataTransfer.getData("answerContent");
                listInputs[j].style.display = "none";
                // event.dataTransfer.setData("answerContent", "");
                // listInputs[i].value = event.dataTransfer.getData("answerContent");
              });
            }
          }
        });
      });
      let collapse = document.getElementsByClassName("ant-collapse-header");
      if (collapse && collapse.length && collapse.length > 0) {
        for (let i = 0; i < collapse.length; i++) {
          if (
            !Array.from(collapse[i].parentNode.parentNode.classList).includes(
              "remove-collapse"
            )
          ) {
            collapse[i].setAttribute("aria-expanded", "true");
          }
        }
      }
      let collapseContent = document.getElementsByClassName(
        "ant-collapse-content"
      );
      if (
        collapseContent &&
        collapseContent.length &&
        collapseContent.length > 0
      ) {
        for (let j = 0; j < collapseContent.length; j++) {
          collapseContent[j].style.display = "grid";
        }
      }
    }, 500);
    setTimeout(() => {
      let audio = document.getElementById("audioMain");
      if (document.getElementById("pbrate")) {
        document.getElementById("pbrate").oninput = function () {
          var value = ((this.value - this.min) / (this.max - this.min)) * 100;
          this.style.background =
            "linear-gradient(to right, #000 0%, #000 " +
            value +
            "%, #ccc " +
            value +
            "%, #ccc 100%)";
        };
      }

      let display = document.getElementById("spanMain");
      let displayvalue = (val) => {
        return parseFloat(val, 10) + "x ";
      };
      let playbackrate = document.getElementById("pbrate");
      if (display) {
        display.innerText = displayvalue(audio?.playbackRate);
      }
      playbackrate.addEventListener("change", (e) => {
        audio.playbackRate = playbackrate.value;
        display.innerText = displayvalue(playbackrate.value);
      });
    }, 1000);

    Test.getTestById(this.$route.params.exerciseId)
      .then((response) => {
        const data = response.data.data;
        this.currentSubject = data.subject;
        data.listTopics.map((topic) => {
          topic.listQuestionGroups.map((questionGroup) => {
            questionGroup.listQuestions.map((question) => {
              question.valueForRadio = null;
            });
          });
        });
        this.fileListeningUrl = data.fileListeningUrl;
        this.testName = data.testName;
        this.testDescription = data.testDescription;
        this.listTopics = data.listTopics;
        this.totalTopics = data.listTopics.length - 1;
        this.totalQuestions = parseInt(data.totalQuestions);
        this.testType = data.testType;
        for (let i = 0; i < this.totalQuestions; i++) {
          this.studentCheckedKeys.push({
            content: "",
            isReview: false,
          });
        }
        this.isShowPoint = data.isShowPoint;
        let [hours, minutes, seconds] = data.countDownTime.split(":");
        this.countDown =
          Date.now() +
          parseInt(hours) * 60 * 60 * 1000 +
          parseInt(minutes, 0) * 60 * 1000 +
          parseInt(seconds, 0) * 1000;
        this.startCountdownWatcher();
      })
      .catch((e) => {
        console.log("e", e);
      });
    QuestionType.getListQuestionType()
      .then((response) => {
        this.listQuestionType = response.data.data;
      })
      .catch((e) => {
        console.log("e", e);
      });
    StudentKeys.getCurrentStudentKeyByClassAndTestIdAndStudentId({
      classId: this.classId,
      testId: this.$route.params.exerciseId,
      studentId: this.userProfile.id,
    })
      .then((response) => {
        const responseData = response.data.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        if (responseData && responseData.length > 0 && responseData[0]) {
          this.currentStudentKeyId = response.data.data[0]._id;
          this.listStudentKeys = response.data.data[0].studentKeys;
          //highlight wrong answer
          setTimeout(() => {
            this.listStudentKeys.forEach((studentKey, index) => {
              const cardContainerDom = document.getElementsByClassName(
                index + 1
              )[0];
              if (
                cardContainerDom &&
                cardContainerDom.getElementsByClassName(
                  "ant-collapse-header"
                ) &&
                !this.listStudentKeys[index].isCorrect
              ) {
                cardContainerDom
                  .getElementsByClassName("ant-collapse-header")[0]
                  ?.setAttribute(
                    "style",
                    "background : rgba(255, 0, 0, 0.1); margin :  0.5rem 0"
                  );
              }
              if (
                studentKey.questionType ===
                "Multiple choice with more than one answer"
              ) {
                this.selectCheckBox(studentKey.key);
              }
            });
          }, 300);
          //
          this.replaceAnswerBoxWithContent();
          this.listTopics = this.listTopics.map((topic, topicIndex) => {
            topic.listQuestionGroups = topic.listQuestionGroups.map(
              (questionGroup, questionGroupIndex) => {
                questionGroup.listQuestions = questionGroup.listQuestions.map(
                  (question, questionIndex) => {
                    const keyIndex = Number(question.questionNumber - 1);
                    if (
                      question.questionType === "True/False/Not Given" ||
                      question.questionType === "Yes/No/Not Given"
                    ) {
                      if (this.listStudentKeys[keyIndex]) {
                        const key = this.listStudentKeys[keyIndex].key;
                        question.valueForRadio =
                          key === "True" || key === "Yes"
                            ? 0
                            : key === "False" || key === "No"
                            ? 1
                            : 2;
                      }
                    }
                    if (
                      [
                        "Matching Information",
                        "Map Labelling (dropdown)",
                        "Multiple choice with one answer",
                      ].includes(question.questionType)
                    ) {
                      const key = this.listStudentKeys[keyIndex].key;
                      question.valueForRadio = key;
                    }
                    if (
                      question.questionType ===
                      "Multiple choice with one answer"
                    ) {
                      question.valueForRadio = question.listAnswers.findIndex(
                        (answer) =>
                          answer === this.listStudentKeys[keyIndex].key
                      );
                    }

                    return question;
                  }
                );

                return questionGroup;
              }
            );
            return topic;
          });

          this.loading = false;
        } else {
          const studentKey = {
            classId: this.classId,
            testId: this.testId,
            listKeys: this.studentCheckedKeys,
            totalQuestions: this.listTopics.totalQuestions,
            status: "started",
          };
          StudentKeys.createStudentKey(studentKey)
            .then((response) => {
              this.currentStudentKeyId = response.data.newStudentKeyCreated._id;
              this.listStudentKeys = [];
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      })
      .catch((e) => {
        console.log("e", e);
        this.loading = false;
      });
  },
  computed: {
    selection() {
      return window.getSelection();
    },
    userProfile() {
      return JSON.parse(localStorage.getItem("userProfile"));
    },
    classId() {
      return this.$route.params.id;
    },
    testId() {
      return this.$route.params.exerciseId;
    },
    _() {
      return _;
    },
  },
  methods: {
    startDrag(event, answer) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData(
        "answerContent",
        answer
          ? answer.replace("[", "").replace("]", "")
          : answer.replace("[", "")
      );
    },
    checkIfContain(content) {
      return false;
    },
    replaceAnswerBoxWithContent() {
      setTimeout(() => {
        this.listStudentKeys.forEach((studentKey, index) => {
          const answerInputs = document.getElementsByClassName("answerTextBox");
          for (let input of answerInputs) {
            const parent = input.closest("p");

            if (parent) {
              const strongElement = [...parent.querySelectorAll("strong")]
                .reverse()
                .find(
                  (strong) =>
                    strong.compareDocumentPosition(input) &
                    Node.DOCUMENT_POSITION_FOLLOWING
                );

              if (strongElement) {
                const number = strongElement.textContent.trim();
                const pTag = document.createElement("strong");
                const studentAnswer = this.listStudentKeys[number - 1];
                pTag.setAttribute(
                  "style",
                  `color : ${studentAnswer.isCorrect ? "green" : "red"}`
                );
                pTag.textContent = `${studentAnswer.key}`;
                parent.replaceChild(pTag, input);
              }
            }
          }
        });
      }, 300);
    },

    onReady(editor) {
      editor.enableReadOnlyMode("my-feature-id");
      editor.ui.getEditableElement();
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
    handleStopAudio() {
      let audio = document.getElementById("audioMain");
      audio.pause();
    },
    handleChangeFontSize(val) {
      const fontSize = `${val.target.value}px`;
      if (this.testType === "listening") {
        this.changeFont(
          document.getElementsByClassName("test-left")[0],
          fontSize
        );
        this.changeFont(
          document.getElementsByClassName("test-left")[1],
          fontSize
        );
        this.changeFont(
          document.getElementsByClassName("test-left")[2],
          fontSize
        );
        this.changeFont(
          document.getElementsByClassName("test-left")[3],
          fontSize
        );
      } else {
        this.changeFont(
          document.getElementsByClassName("responsive-reading")[0],
          fontSize
        );
        this.changeFont(
          document.getElementsByClassName("responsive-reading")[1],
          fontSize
        );
        this.changeFont(
          document.getElementsByClassName("responsive-reading")[2],
          fontSize
        );
        this.changeFont(
          document.getElementsByClassName("responsive-reading")[3],
          fontSize
        );
      }
    },
    startCountdownWatcher() {
      this.interval = setInterval(() => {
        this.isShowRedCountDown = this.countDown - Date.now() < 2 * 60 * 1000;
      }, 1000);
    },
    changeFont(element, fontSize) {
      element.style.fontSize = fontSize.toString();
      for (var i = 0; i < element.children.length; i++) {
        this.changeFont(element.children[i], fontSize);
      }
    },

    addIndex(arr) {
      let newArr = [];
      arr.forEach((element, index) => {
        newArr.push({ ...element, index: index + 1 });
      });
      return newArr;
    },
    highlightRange(range) {
      let newNode = document.createElement("div");
      newNode.setAttribute(
        "style",
        "background-color: yellow; display: inline;"
      );
      range.surroundContents(newNode);
    },
    highlightSelection() {
      let userSelection = window.getSelection().getRangeAt(0);
      this.highlightRange(userSelection);
    },
    dropToAnswersList(event, questionGroup, question) {
      for (let i = 0; i < questionGroup.listKeys.length; i++) {
        if (
          questionGroup.listKeys[i] ===
          event.dataTransfer.getData("answerContent")
        ) {
          questionGroup.listKeys[i] = "";
        }
      }
      if (
        question.listAnswersWithoutKey.indexOf(
          event.dataTransfer.getData("answerContent")
        ) === -1
      ) {
        question.listAnswersWithoutKey.push(
          event.dataTransfer.getData("answerContent")
        );
      }
      questionGroup.oldKey = "";
    },
    endDrag(event, questionGroup, question) {
      question.listAnswersWithoutKey = _.difference(
        question.listAnswersWithoutKey,
        questionGroup.listKeys
      );
      if (
        questionGroup.oldKey !== "" &&
        question.listAnswersWithoutKey.indexOf(questionGroup.oldKey) === -1
      ) {
        question.listAnswersWithoutKey.push(questionGroup.oldKey);
      }
    },
    handleChangePage(index) {
      this.currentIndex = index;
    },

    handleAddAnswerKeyCheckBox(val, question, answerIndex) {
      if (
        val &&
        question.listKeys.indexOf(question.listAnswers[answerIndex]) === -1
      ) {
        question.listKeys.push(question.listAnswers[answerIndex]);
      } else {
        if (
          question.listKeys.indexOf(question.listAnswers[answerIndex]) !== -1
        ) {
          question.listKeys.splice(
            question.listKeys.indexOf(question.listAnswers[answerIndex]),
            1
          );
        }
      }
      if (question.listKeys.length === question.totalKeys) {
      }
    },
    handleAddKey(val, question, answerIndex) {
      question.listKeys[answerIndex] = val;
    },
    makeid(length) {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    handleReview(index) {
      let totalQuestionBefore = 0;
      let totalQuestionAfter = 0;
      if (this.currentIndex === 0) {
        this.listTopics[0].listQuestionGroups.map((questionGroup) => {
          questionGroup.listQuestions.map((question) => {
            totalQuestionAfter += question.numberOfQuestion;
          });
        });
      } else {
        for (let i = 0; i < this.currentIndex; i++) {
          this.listTopics[i].listQuestionGroups.map((questionGroup) => {
            questionGroup.listQuestions.map((question) => {
              totalQuestionBefore += question.numberOfQuestion;
            });
          });
        }
        for (let i = 0; i < this.currentIndex + 1; i++) {
          this.listTopics[i].listQuestionGroups.map((questionGroup) => {
            questionGroup.listQuestions.map((question) => {
              totalQuestionAfter += question.numberOfQuestion;
            });
          });
        }
      }
      if (index < totalQuestionBefore) {
        if (this.currentIndex === 0) {
          this.currentIndex--;
        } else {
          let newTotalQuestionBefore = 0;
          for (let i = 0; i < this.currentIndex - 1; i++) {
            this.listTopics[i].listQuestionGroups.map((questionGroup) => {
              questionGroup.listQuestions.map((question) => {
                newTotalQuestionBefore += question.numberOfQuestion;
              });
            });
          }
          if (index < newTotalQuestionBefore) {
            this.currentIndex -= 2;
          } else {
            this.currentIndex--;
          }
        }
      }
      if (index >= totalQuestionAfter) {
        if (this.currentIndex === this.listTopics.length) {
          this.currentIndex++;
        } else {
          let newTotalQuestionAfter = 0;
          for (let i = 0; i < this.currentIndex + 2; i++) {
            this.listTopics[i].listQuestionGroups.map((questionGroup) => {
              questionGroup.listQuestions.map((question) => {
                newTotalQuestionAfter += question.numberOfQuestion;
              });
            });
          }
          if (index >= newTotalQuestionAfter) {
            this.currentIndex += 2;
          } else {
            this.currentIndex++;
          }
        }
      }
      setTimeout(() => {
        const collection = document.getElementsByClassName(`${index + 1}`);
        if (collection && collection[0]) {
          let position = collection[0].getBoundingClientRect();
          collection[0].scrollIntoView();
          // window.scrollTo(position.left, position.top + window.scrollY - 300);
          collection[0].style.background = "lightblue";
          setTimeout(() => {
            collection[0].style.background = "white";
          }, 2000);
        }
      }, 100);

      this.currentButtonIndex = index;
    },
    handleBackToClassDetail() {
      this.$router.push({
        name: "quizExercise",
        params: { id: this.classId },
      });
    },
    selectCheckBox(text) {
      const elements = document.querySelectorAll("*");
      const matchingElement = Array.from(elements).find(
        (el) => el.textContent.trim() === text
      );

      if (matchingElement) {
        const input = matchingElement.parentElement.parentElement.querySelector(
          'input[type="checkbox"]'
        );
        if (input) {
          input.setAttribute("aria-checked", true);
        }
        const icon =
          matchingElement.parentElement.parentElement.querySelector("i");

        if (icon) {
          icon.classList.add("mdi-checkbox-marked");
          console.log(icon);
        }
      } else {
        console.log("No matching text found in the DOM.");
      }
      return Array.from(elements).find((el) => el.textContent.trim() === text);
    },

    handleCollapse(questionGroup, question, questionIndex) {
      //
    },
    handleCheckReview() {
      this.studentCheckedKeys[this.currentButtonIndex].isReview =
        !this.studentCheckedKeys[this.currentButtonIndex].isReview;
    },
    handleCheckReviewForListening(index) {
      this.studentCheckedKeys[index].isReview =
        !this.studentCheckedKeys[index].isReview;
    },
    handleChangeAudioSource(fileListening) {
      this.fileListeningUrl = fileListening;
    },
    handleChangeBorder() {
      let listAnswerBoxs;
      listAnswerBoxs = document.getElementsByClassName("answer-list");
    },
    handleUnChangeBorder() {
      let listAnswerBoxs;
      listAnswerBoxs = document.getElementsByClassName("answer-list");
    },
    handleSubmit() {
      this.visibleSendKey = true;
    },
    onFinish() {},
    handleCreateClassName(
      topicIndex,
      questionGroupIndex,
      question,
      questionIndex
    ) {
      if (isNaN(question.questionNumber.trim())) {
        let result = "";
        let previousIndex = 0;
        this.listTopics.map((topic, index) => {
          if (index <= topicIndex) {
            topic.listQuestionGroups.map((questionGroup, qIndex) => {
              if (index !== topicIndex) {
                questionGroup.listQuestions.map((ques) => {
                  previousIndex += parseInt(ques.numberOfQuestion);
                });
              } else {
                if (
                  qIndex !== questionGroupIndex &&
                  qIndex < questionGroupIndex
                ) {
                  questionGroup.listQuestions.map((ques) => {
                    previousIndex += parseInt(ques.numberOfQuestion);
                  });
                } else {
                  questionGroup.listQuestions.map((ques, quesIndex) => {
                    if (quesIndex < questionIndex) {
                      previousIndex += parseInt(ques.numberOfQuestion);
                    }
                  });
                }
              }
            });
          }
        });
        for (let i = 0; i < question.numberOfQuestion; i++) {
          result = result.concat(" ", previousIndex + i + 1);
        }
        return result.toString();
      }
      return question.questionNumber.toString();
    },
    formatStartOfQuestion(index) {
      let character = "";
      switch (parseInt(index)) {
        case 0:
          character = "A";
          break;
        case 1:
          character = "B";
          break;
        case 2:
          character = "C";
          break;
        case 3:
          character = "D";
          break;
        case 4:
          character = "E";
          break;
        case 5:
          character = "F";
          break;
        case 6:
          character = "G";
          break;
        case 7:
          character = "H";
          break;
        case 8:
          character = "I";
          break;
        case 9:
          character = "J";
          break;
        case 10:
          character = "K";
          break;
        case 11:
          character = "L";
          break;
        case 12:
          character = "M";
          break;
        case 13:
          character = "N";
          break;
        case 14:
          character = "i";
          break;
        case 15:
          character = "ii";
          break;
        case 16:
          character = "iii";
          break;
        case 17:
          character = "iv";
          break;
        case 18:
          character = "v";
          break;
        case 19:
          character = "vi";
          break;
        case 20:
          character = "vii";
          break;
        case 21:
          character = "viii";
          break;
        case 22:
          character = "ix";
          break;
        case 23:
          character = "x";
          break;
        case 24:
          character = "xi";
          break;
        case 25:
          character = "xii";
          break;
        case 26:
          character = "xiii";
          break;
        case 27:
          character = "xiv";
          break;
        case 28:
          character = "xv";
          break;
      }
      return character;
    },
    formatStartOfQuestionRoman(index) {
      let character = "";
      switch (parseInt(index)) {
        case 0:
          character = "i";
          break;
        case 1:
          character = "ii";
          break;
        case 2:
          character = "iii";
          break;
        case 3:
          character = "iv";
          break;
        case 4:
          character = "v";
          break;
        case 5:
          character = "vi";
          break;
        case 6:
          character = "vii";
          break;
        case 7:
          character = "viii";
          break;
        case 8:
          character = "ix";
          break;
        case 9:
          character = "x";
          break;
        case 10:
          character = "xi";
          break;
        case 11:
          character = "xii";
          break;
        case 12:
          character = "xiii";
          break;
        case 13:
          character = "xiv";
          break;
        case 14:
          character = "xv";
          break;
      }
      return character;
    },
    replaceBracketWithInput(str) {
      const pieces = str.replaceAll(
        "[]",
        '<input class="answerTextBox" style="border: solid 1px; height: 1.8rem; margin:5px; padding: 2px;"/>'
      );
      return pieces;
    },
  },
};
</script>

<style lang="scss">
.p-fix-content {
  width: fit-content;
}
::-webkit-scrollbar {
  width: 0px;
}
.radio-style {
  display: flex;
  justify-content: start;
  background: #f5f5f5;
  border-radius: 5px;
  margin-top: 0.5rem;
  padding: 10px;
  align-items: center;
}
.radio-style-multiple {
  display: flex;
  background: #f5f5f5;
  border-radius: 5px;
  margin-top: 0.5rem;
  padding: 10px;
}
.edu-editor img {
  width: 100% !important;
}
.edu-editor {
  p {
    color: black;
    font-weight: 400;
  }
}
div {
  color: black;
  font-weight: 400;
}
.ant-collapse-header {
  padding-bottom: 0px;
  cursor: pointer !important;
  color: black !important;
}
.set-margin-top {
  margin-top: -1.5rem;
}
.set-small-margin-top {
  margin-top: -1rem;
}
.answer-list {
  min-height: 150px;
}
.ant-btn-sm {
  height: 1.6rem;
  width: 1.6rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.edu-editor {
  overflow: scroll;
}
figure .table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
figure .table::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
figure .table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.responsive-reading-question img {
  width: 100% !important;
}

.responsive-reading-question figure {
  width: 100% !important;
}
.edu-editor img {
  width: 100% !important;
}
.edu-editor table {
  width: 100% !important;
  border: 0.5px solid black !important;
}
.edu-editor table td {
  border: 0.5px solid black !important;
}
.edu-editor table tr {
  border: 0.5px solid black !important;
}
.scc {
  max-width: 30em;
  display: block;
  padding: 10px;
  font-family: Sans-serif;
}
.scc audio {
  width: 100%;
  display: block;
  color: black;
}
.scc div {
  display: flex;
  padding: 0.5em 0;
  gap: 5px;
}
.scc label {
  flex: 1;
}
.scc input[type="range"] {
  flex: 5;
}
.scc span {
  flex: 1;
  text-align: center;
}
.custom-audio {
  margin-left: -1.2rem;
  width: 470px;
}
.review-style {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  margin-top: 1rem;
}
audio::-webkit-media-controls-panel {
  background-color: #fff;
  /* border: 1px solid cadetblue */
}
#pbrate {
  border: solid 0.5px #ccc;
  background: linear-gradient(to right, #000 0%, #000 40%, #ccc 40%, #ccc 100%);
  border-radius: 8px;
  height: 4px;
  width: 400px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #5e5e5e;
}
.listening-site {
  height: 100%;
  background: #fff;
  margin-top: -2.5rem;
  padding: 0rem 0.5% 0rem 0.5%;
  display: flex;
}
.test-left {
  width: 75%;
  margin-top: 1rem;
}
.sticky-note-container {
  height: 250px;
  width: 235px;
  background: #fff;
  position: absolute;
  border: 0.5px solid #000;
}
.sticky-note-header {
  height: 25px;
  justify-content: end;
  display: flex;
  background: #000;
}
.sticky-note-body {
  background: #fff;
}
.sticky-note-input {
  width: 100%;
  min-height: 219px;
}
textarea:focus {
  outline: none !important;
}
.test-right {
  width: 22%;
  background: #edd31d;
  padding-left: 0.2rem;
  margin-top: 2.55rem;
  position: fixed;
  right: 0%;
  top: 2%;
  height: 100vh;
}
.test-left-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 22.8rem;
  margin: 2rem -1rem -1rem 0.5rem;
}
.normal-primary-button {
  border-radius: 20%;
  margin-left: 10px;
  color: white;
  font-size: 13.5px;
  font-weight: 500;
  width: 40px !important;
}
.normal-button {
  border-radius: 20%;
  margin-left: 10px;
  font-size: 13.5px;
  font-weight: 500;
  width: 40px !important;
}
.resize-button-primary-number {
  border-radius: 20%;
  margin-left: 10px;
  color: white;
  font-size: 13.5px;
  font-weight: 500;
  width: 40px !important;
}
.resize-button-number {
  margin-left: 10px;
  font-size: 13.5px;
  font-weight: 500;
  width: 40px !important;
  border-radius: 20%;
}
.resize-button-number span {
  margin-left: -5px;
}
.resize-button-primary-number span {
  margin-left: -5px;
}
.remove-card-body-padding .ant-card-body {
  padding: 0px !important;
}
.remove-card-body-padding {
  position: fixed;
  width: 100%;
  padding: 0 1% 0 1%;
  z-index: 1000;
}
.type-of-answer {
  margin-left: 1rem;
  margin-top: 1.5rem;
}
.type-of-answer-item {
  display: flex;
}
.need-review-answer {
  width: 15px;
  height: 15px;
  border: 1px solid white;
  border-radius: 50%;
  background: white;
}
.need-review-answer-container {
  display: flex;
  align-items: center;
}
.high-light-container {
  background: #000;
  width: fit-content;
  display: flex;
  white-space: pre-wrap;
  justify-content: space-between;
  position: absolute;
  border-radius: 5px;
  user-select: none;
}
.high-light-btn {
  padding: 1px;
  margin-left: 0.1rem;
  border-right: 1px solid #ccc;
}
.high-light-btn:hover {
  background: #5e5e5e;
}
.need-review-answer-label {
  color: black;
  margin-bottom: 1px;
  margin-left: 0.5rem;
}
.range-slider__range {
  -webkit-appearance: none;
  width: 75px;
  height: 3px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
}
.range-slider__range::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #2c3e50;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
.range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #2c3e50;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
}
.range-slider__value {
  display: inline-block;
  position: relative;
  width: 60px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #2c3e50;
  padding: 5px 10px;
  margin-left: 8px;
}
.btn_style {
  border: black;
}
.normal-answer {
  width: 15px;
  height: 15px;
  border: 1px solid white;
  background: white;
  border-radius: 20%;
}
.normal-answered {
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  background: #000;
  border-radius: 20%;
}
.need-review-answered {
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  background: #000;
  border-radius: 50%;
}
.submit-btn {
  position: absolute;
  bottom: 15%;
  right: 10%;
}
.test-right-for-mobile {
  position: fixed;
  bottom: -2px;
  width: 100vw;
  margin-left: -1%;
}
.responsive-content-box {
  .ant-collapse-content-box {
    padding: 0px !important;
  }
}
@media (max-width: 1200px) {
  .listening-site-responsive {
    padding: 0 1% 0 1% !important;
    height: 100vh;
  }
  .responsive-countdown-mobile {
    .ant-statistic-content-value {
      color: white;
    }
  }
  .responsive-listening-site-header {
    padding: 0 0% 0 1% !important;
  }
  .responsive-countdown {
    right: 1% !important;
  }
  .test-right {
    right: 1%;
    width: 34%;
  }
  .type-of-answer-item {
    display: grid;
  }
  .need-review-answer-container {
    margin-left: 0px !important;
  }
}
@media (max-width: 1050px) {
  .custom-audio {
    width: 50vw;
  }
  .slider-main {
    width: 35vw !important;
  }
  .slider-font-size {
    width: 35vw !important;
  }
}
.test-right-for-mobile {
  display: none;
}
@media (max-width: 1000px) {
  .test-right {
    display: none;
  }
  .test-left {
    width: 100%;
  }
  .responsive-reading-container {
    height: 100vh !important;
  }
  .responsive-reading-topic {
    height: 65vh !important;
  }
  .responsive-reading-question {
    height: 65vh !important;
  }
  .responsive-card-container {
    height: 74% !important;
  }
  .responsive-countdown {
    display: none !important;
  }
  .test-right-for-mobile {
    display: grid !important;
  }
  .responsive-content-collapse {
    height: 50vh !important;
    overflow: scroll;
  }
}

@media (max-width: 660px) {
  .responsive-card-container {
    height: 90% !important;
  }
  .responsive-reading {
    display: grid !important;
  }
  .responsive-divider {
    display: grid !important;
  }
  .responsive-reading-topic {
    width: 95vw !important;
    max-width: 100vw !important;
    height: 41vh !important;
    max-height: 45vh !important;
  }
  .responsive-reading-question {
    width: 95vw !important;
    max-width: 100vw !important;
    height: 40vh !important;
    max-height: 45vh !important;
  }
  .responsive-button-submit {
    bottom: 2px !important;
  }
}
@media only screen and (max-width: 1050px) and (orientation: portrait) {
  .responsive-card-container {
    height: 90% !important;
  }
  .responsive-divider {
    display: grid !important;
  }
  .responsive-reading {
    display: grid !important;
    height: 90%;
  }
  .responsive-reading-topic {
    width: 95vw !important;
    max-width: 100vw !important;
    height: 42vh !important;
    max-height: 45vh !important;
  }
  .responsive-reading-question {
    width: 95vw !important;
    max-width: 100vw !important;
    height: 40vh !important;
    max-height: 45vh !important;
  }
}
@media (max-width: 500px) {
  .responsive-listening-site-header-item {
    display: grid !important;
    margin-left: 0.5rem;
  }
  .custom-audio {
    width: 100vw;
  }
  .slider-main {
    width: 92vw !important;
    padding-bottom: 1rem;
  }
  .test-left {
    margin-top: 8.5rem !important;
    margin-bottom: 4rem !important;
  }
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .responsive-card-container {
    height: 74% !important;
  }
  @media only screen and (max-width: 500px) and (orientation: landscape) {
  }
}
@media only screen and (max-width: 500px) and (orientation: portrait) {
  .responsive-card-container {
    height: 88% !important;
  }
  .responsive-reading-topic {
    width: 90vw !important;
    height: 39vh !important;
  }
  .responsive-reading-question {
    width: 90vw !important;
    height: 39vh !important;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-red {
  animation: pulse 1s infinite;
}
</style>
